import {
    SET_ACTIVE_TAB,
    OPEN_USER_PROFILE_SIDEBAR,
    CLOSE_USER_PROFILE_SIDEBAR,
    OPEN_VIEW_CONTACT,
    CLOSE_VIEW_CONTACT,
    SET_CONTACT_DETAILS,
    SET_PROFILEPIC_URLS,
    SET_MYPROFILEPIC_URL,
    SET_SETTINGS_TAB,
    RESET_LAYOUT_STATE
} from "./constants";

export const setActiveTab = (tabId) => ({
    type: SET_ACTIVE_TAB,
    payload: tabId
});

export const openUserSidebar = () => ({
    type: OPEN_USER_PROFILE_SIDEBAR
});

export const closeUserSidebar = () => ({
    type: CLOSE_USER_PROFILE_SIDEBAR
});

export const openViewContact = () => ({
    type: OPEN_VIEW_CONTACT
});

export const closeViewContact = () => ({
    type: CLOSE_VIEW_CONTACT
});

export const setContact = (contact) => ({
    type: SET_CONTACT_DETAILS,
    payload: contact
});

export const setProfilePicUrls = (Urls) => ({
    type: SET_PROFILEPIC_URLS,
    payload: Urls
});

export const setMyProfilePicUrl = (Url) => ({
    type: SET_MYPROFILEPIC_URL,
    payload: Url
});

export const setSettingsTab = (tabId) => ({
    type: SET_SETTINGS_TAB,
    payload: tabId
});

export const resetLayoutState = () => ({
    type: RESET_LAYOUT_STATE
});