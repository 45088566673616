import { combineReducers } from 'redux';

import Auth from './auth/reducers';
import Chat from './chat/reducers';
import Layout from './layout/reducer';
import ArchiveChat from './archive/reducers';
import Notebook from './notebook/reducers';
import AdminSearch from './search/reducers';
export default combineReducers({
    Auth,
    Chat,
    Layout,
    ArchiveChat,
    Notebook,
    AdminSearch
});