import {
    CHAT_USER, CHANGE_ACTIVE_CONVERSATION_ID, ACTIVE_USER, FULL_USER, GET_CONTACTS, FETCH_MESSAGES, ALL_MESSAGES, RECENT_CONVERSATION_LIST, RECENT_NEW_CONVERSATION, SET_IS_OPEN_RULES, SET_IS_OPEN_LOCATION_RULES, SET_ACTIVE_CONVERSATION, RESET_CHAT_STATE, SET_LOADING
} from './constants';


export const chatUser = () => ({
    type: CHAT_USER
});

export const changeActiveConversationId = (conversationId) => ({
    type: CHANGE_ACTIVE_CONVERSATION_ID,
    payload: conversationId
});

export const setActiveConversation = (conversation) => ({
    type: SET_ACTIVE_CONVERSATION,
    payload: conversation
});

export const activeUser = (userId) => ({
    type: ACTIVE_USER,
    payload: userId
});

export const setFullUser = (userInfo) => ({
    type: FULL_USER,
    payload: userInfo
});

export const getContacts = (contacts) => ({
    type: GET_CONTACTS,
    payload: contacts
});

export const fetchMessages = (messages) => ({
    type: FETCH_MESSAGES,
    payload: messages
});

export const setAllMessages = (allMessages) => ({
    type: ALL_MESSAGES,
    payload: allMessages
});

export const setRecentConversationList = (recentConversationList) => ({
    type: RECENT_CONVERSATION_LIST,
    payload: recentConversationList
});

export const setRecentNewConversation = (recentNewConversation) => ({
    type: RECENT_NEW_CONVERSATION,
    payload: recentNewConversation
});

export const setIsOpenRulesMsg = (isOpen) => ({
    type: SET_IS_OPEN_RULES,
    payload: isOpen
});

export const setIsOpenLocationRulesMsg = (isOpen) => ({
    type: SET_IS_OPEN_LOCATION_RULES,
    payload: isOpen
});

export const resetChatState = () => ({
    type: RESET_CHAT_STATE
});

export const setIsLoading = (isLoading) => ({
    type: SET_LOADING,
    payload: isLoading
});