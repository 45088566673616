import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGOUT_USER,
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    FORGET_PASSWORD,
    FORGET_PASSWORD_SUCCESS,
    API_FAILED,
    USER_DETAILS,
    RESET_AUTH_STATE
} from './constants';

import { getLoggedInUser, getLoggedUserDetails } from '../../helpers/authUtils';

const INIT_STATE = {
    user: getLoggedInUser(),
    loading: false,
    error: null,
    userDetails: getLoggedUserDetails()
};


const Auth = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOGIN_USER:
            return { ...state, loading: true, ...state.userDetails };
        case LOGIN_USER_SUCCESS:
            return { ...state, user: action.payload, loading: false, error: null, ...state.userDetails };

        case REGISTER_USER:
            return { ...state, loading: true, ...state.userDetails };
        case REGISTER_USER_SUCCESS:
            return { ...state, user: action.payload, loading: false, error: null, ...state.userDetails };
        case USER_DETAILS:
            return { ...state, userDetails: action.payload }

        case LOGOUT_USER:
            return { ...state, user: null, userDetails: [] };

        case FORGET_PASSWORD:
            return { ...state, loading: true, ...state.userDetails };
        case FORGET_PASSWORD_SUCCESS:
            return { ...state, passwordResetStatus: action.payload, loading: false, error: null, ...state.userDetails };

        case API_FAILED:
            return { ...state, loading: false, error: action.payload, ...state.userDetails };

        case RESET_AUTH_STATE:
            return INIT_STATE;

        default: return { ...state };
    }
}

export default Auth;