import {
    SET_ACTIVE_FOLDER,
    SET_FOLDERS,
    SET_FILES,
    ON_CAMERA_CLICKED,
    SET_SPACE_USED,
    SET_ALERT_MESSAGE,
    SET_ALERT_COLOR,
    SET_SHARED_FOLDERS,
    SET_IS_OPEN_LOC_RULES,
    RESET_NOTEBOOK_STATE,
    SET_IS_OPEN_LOADER_MODAL
} from './constants';

const INIT_STATE = {
    active_folder: null,
    files: [],
    folders: [],
    sharedFolders: [],
    onCameraClicked: null,
    spaceUsed: 0,
    alertMessage: null,
    alertColor: 'danger',
    isOpenLocationRules: false,
    isOpenLoaderModal: false
};

const Notebook = (state = INIT_STATE, action) => {
    switch(action.type) {
        case SET_ACTIVE_FOLDER:
            return { ...state, active_folder: action.payload }

        case SET_FOLDERS:
            return { ...state, folders: [...action.payload] }

        case SET_SHARED_FOLDERS:
            return { ...state, sharedFolders: [...action.payload] }

        case SET_FILES:
            return { ...state, files: [...action.payload] }
        
        case ON_CAMERA_CLICKED:
            return { ...state, onCameraClicked: action.payload }

        case SET_SPACE_USED:
            return { ...state, spaceUsed: action.payload }

        case SET_ALERT_MESSAGE:
            return { ...state, alertMessage: action.payload }

        case SET_ALERT_COLOR:
            return { ...state, alertColor: action.payload }

        case SET_IS_OPEN_LOC_RULES:
            return { ...state, isOpenLocationRules: action.payload }

        case RESET_NOTEBOOK_STATE:
            return INIT_STATE;

        case SET_IS_OPEN_LOADER_MODAL:
            return { ...state, isOpenLoaderModal: action.payload }

        default: return { ...state };
    }
}

export default Notebook;