import React, { Suspense } from 'react';
import { HashRouter, Switch, Route, Redirect } from 'react-router-dom';

//import routes
import { authProtectedRoutes, publicRoutes } from './routes';

//import layouts
import NonAuthLayout from "../layouts/NonAuth";
import AuthLayout from "../layouts/AuthLayout/";
import { connect } from 'react-redux';

// handle auth and authorization
const AppRoute = ({ component: Component, layout: Layout, isAuthProtected, ...rest }) => {
    return <Route {...rest} render={props => {

        if (isAuthProtected && !localStorage.getItem("authUser")) {
            return (
                <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
            );
        } else if (!isAuthProtected && localStorage.getItem('authUser')) {
            return (
                <Redirect to={{ pathname: "/dashboard", state: { from: props.location } }} />
            );
        }
        // authorised so return component
        return <Layout><Component {...props} /></Layout>
    }} />
}

/**
 * Main Route component
 */
const Routes = (props) => {
    return (
        // rendering the router with layout
        <HashRouter>
            <React.Fragment>
                <Suspense fallback={<div></div>} >
                    <Switch>
                        {/* public routes */}
                        {publicRoutes.map((route, idx) =>
                            <AppRoute path={route.path} layout={NonAuthLayout} component={route.component}
                                key={idx} isAuthProtected={false} />
                        )}

                        {/* private/auth protected routes */}
                        {authProtectedRoutes.map((route, idx) => {
                            if ((route.path === '/users') && props.userDetails && props.userDetails.info && (!(props.userDetails.info.isAdmin || props.userDetails.info.isSuperAdmin))) {
                                return < AppRoute path='/dashboard' layout={AuthLayout} component='Dashboard' Dashboard
                                    key={idx} isAuthProtected={true} />
                            } else {
                                return < AppRoute path={route.path} layout={AuthLayout} component={route.component}
                                    key={idx} isAuthProtected={true} />
                            }
                        })}
                    </Switch>
                </Suspense>
            </React.Fragment>
        </HashRouter>
    );
}

const mapStatetoProps = state => {
    const { userDetails } = state.Auth;
    return { userDetails };
};
export default connect(mapStatetoProps)(Routes);