export const CHAT_USER = "CHAT_USER";
export const ACTIVE_USER = "ACTIVE_USER";
export const FULL_USER = "FULL_USER";
export const GET_CONTACTS = "GET_CONTACTS";
export const CHANGE_ACTIVE_CONVERSATION_ID = "CHANGE_ACTIVE_CONVERSATION_ID";
export const SET_ACTIVE_CONVERSATION = "SET_ACTIVE_CONVERSATION";
export const FETCH_MESSAGES = "FETCH_MESSAGES";
export const ALL_MESSAGES = "ALL_MESSAGES";
export const RECENT_CONVERSATION_LIST = "RECENT_CONVERSATION_LIST";
export const RECENT_NEW_CONVERSATION = "RECENT_NEW_CONVERSATION";
export const SET_IS_OPEN_RULES = "SET_IS_OPEN_RULES";
export const SET_IS_OPEN_LOCATION_RULES = "SET_IS_OPEN_LOCATION_RULES";
export const RESET_CHAT_STATE = 'RESET_CHAT_STATE';
export const SET_LOADING = "SET_LOADING";