export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB";
export const OPEN_USER_PROFILE_SIDEBAR = 'OPEN_USER_PROFILE_SIDEBAR';
export const CLOSE_USER_PROFILE_SIDEBAR = 'CLOSE_USER_PROFILE_SIDEBAR';
export const SET_CONVERSATION_NAME_IN_OPEN_CHAT = 'SET_CONVERSATION_NAME_IN_OPEN_CHAT';
export const OPEN_VIEW_CONTACT = "OPEN_VIEW_CONTACT";
export const CLOSE_VIEW_CONTACT = "CLOSE_VIEW_CONTACT";
export const SET_CONTACT_DETAILS = "SET_CONTACT_DETAILS";
export const SET_PROFILEPIC_URLS = "SET_PROFILEPIC_URLS";
export const SET_MYPROFILEPIC_URL = "SET_MYPROFILEPIC_URL";
export const SET_SETTINGS_TAB = "SET_SETTINGS_TAB";
export const RESET_LAYOUT_STATE = 'RESET_LAYOUT_STATE';