import React, { useCallback } from 'react';
import { Link } from "react-router-dom";
import { Nav, NavItem, UncontrolledTooltip } from "reactstrap";
import classnames from "classnames";
import { connect } from "react-redux";

import { closeViewContact } from "../../redux/layout/actions"

import { setActiveTab } from "../../redux/actions";

//Import Images
import logo from "../../assets/images/icon_splash_logo.png"

function LeftSidebarMenu(props) {

    const toggleTab = useCallback((tab) => {
        props.setActiveTab(tab)
    }, [props.setActiveTab]);

    const activeTab = props.activeTab;
    return (
        <React.Fragment>
            <div className="side-menu flex-lg-column mr-lg-1">
                {/* LOGO */}
                <div className="navbar-brand-box">
                    <Link to="/" className="logo logo-dark">
                        <span className="logo-sm">
                            <img src={logo} alt="logo" height="30" />
                        </span>
                    </Link>

                    <Link to="/" className="logo logo-light">
                        <span className="logo-sm">
                            <img src={logo} alt="logo" height="30" />
                        </span>
                    </Link>
                </div>
                {/* end navbar-brand-box  */}

                {/* Start side-menu nav */}
                <div className="flex-lg-column my-auto">
                    <Nav pills className="side-menu-nav justify-content-center" role="tablist">
                        <NavItem id="Chats">
                            <Link to='/dashboard/' id="pills-chat-tab" className={classnames({ active: activeTab === 'chat' }, 'nav-link')} onClick={() => { toggleTab('chat') }}>
                                <i className="ri-message-2-line"></i>
                            </Link>
                        </NavItem>
                        <UncontrolledTooltip target="Chats" placement="top">
                            Messages
                        </UncontrolledTooltip>
                        <NavItem id="Archives">
                            <Link to='/dashboard/archive' id="pills-archives-tab" className={classnames({ active: activeTab === 'archives' }, 'nav-link')} onClick={() => { toggleTab('archives') }} >
                                <i className="ri-folder-zip-line"></i>
                            </Link>
                        </NavItem>
                        <UncontrolledTooltip target="Archives" placement="top">
                            Archives
                        </UncontrolledTooltip>
                        <NavItem id="Notebooks">
                            <Link to='/dashboard/notebooks' id="pills-notebooks-tab" className={classnames({ active: activeTab === 'notebooks' }, 'nav-link')} onClick={() => { toggleTab('notebooks') }}>
                                <i className="ri-book-2-line"></i>
                            </Link>
                        </NavItem>
                        <UncontrolledTooltip target="Notebooks" placement="top">
                            Notebooks
                        </UncontrolledTooltip>
                        <NavItem id="Contacts">
                            <Link to='/dashboard/contacts' id="pills-contacts-tab" className={classnames({ active: activeTab === 'contacts' }, 'nav-link')} onClick={() => { toggleTab('contacts') }}>
                                <i className="ri-contacts-book-line"></i>
                            </Link>
                        </NavItem>
                        <UncontrolledTooltip target="Contacts" placement="top">
                            Contacts
                        </UncontrolledTooltip>
                        <NavItem id="Settings">
                            <Link to='/dashboard/settings' id="pills-setting-tab" className={classnames({ active: activeTab === 'settings' }, 'nav-link')} onClick={() => { toggleTab('settings') }}>
                                <i className="ri-settings-5-line"></i>
                            </Link>
                        </NavItem>
                        <UncontrolledTooltip target="Settings" placement="top">
                            Settings
                        </UncontrolledTooltip>
                        <NavItem id="InviteUsers">
                            <a id="pills-invite-users-tab" className='nav-link' target="_blank"
                                href="mailto:?subject=Invitation to join EIE legal&amp;body=Hello%2C%20%0A%0AI%20would%20like%20to%20invite%20you%20to%20try%20out%20EIE%20legal.%0A%0A%20A%20secure%20and%20encrypted%20communications%20app.%0A%0A%20Link%3A%0A%20https%3A%2F%2Fencryptedinformationexchange.com"
                            >
                                <i className="ri-user-add-line"></i>
                            </a>
                        </NavItem>
                        <UncontrolledTooltip target="InviteUsers" placement="top">
                            Invite Users
                        </UncontrolledTooltip>
                        <NavItem id="payment">
                            <Link to='/dashboard/purchase' id="pills-payment-tab" className={classnames({ active: activeTab === 'payment' }, 'nav-link')} onClick={() => { toggleTab('payment') }}  >
                                <i className="ri-bank-card-line"></i>
                            </Link>
                        </NavItem>
                        <UncontrolledTooltip target="payment" placement="top">
                            Payment
                        </UncontrolledTooltip>
                        {
                            props.userDetails && props.userDetails.info && (props.userDetails.info.isAdmin || props.userDetails.info.isSuperAdmin) &&
                            <React.Fragment>
                                <NavItem id="users">
                                    <Link to='/users' id="pills-users-tab" className={classnames({ active: activeTab === 'users' }, 'nav-link')} onClick={() => { toggleTab('users') }}    >
                                        <i className="ri-user-line"></i>
                                    </Link>
                                </NavItem>
                                <UncontrolledTooltip target="users" placement="top">
                                    Users
                                 </UncontrolledTooltip>
                                <NavItem id="search">
                                    <Link to='/dashboard/search' id="pills-search-tab" className={classnames({ active: activeTab === 'search' }, 'nav-link')} onClick={() => { toggleTab('search') }}  >
                                        <i className="ri-search-2-line"></i>
                                    </Link>
                                </NavItem>
                                <UncontrolledTooltip target="search" placement="top">
                                    Search
                                </UncontrolledTooltip>
                            </React.Fragment>
                        }
                        <NavItem id="Logout">
                            <Link className='nav-link' id="pills-logout-tab" to="/logout">
                                <i className="ri-shut-down-line"></i>
                            </Link>
                        </NavItem>
                        <UncontrolledTooltip target="Logout" placement="top">
                            Logout
                        </UncontrolledTooltip>
                    </Nav>
                </div>
                {/* end side-menu nav */}
            </div>
        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    const { userDetails } = state.Auth;
    return {
        ...state.Layout, userDetails
    };
};

export default connect(mapStatetoProps, {
    setActiveTab, closeViewContact
})(LeftSidebarMenu);