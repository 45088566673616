import firebase from 'firebase/app';
// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import 'firebase/storage';

class FirebaseAuthBackend {
    constructor(firebaseConfig) {
        let currentThis = this;
        if (firebaseConfig) {
            // Initialize Firebase
            firebase.initializeApp(firebaseConfig);
        }

    }

    checkEnabledNotifications = async () => {
        let currentThis = this;
        const result = await Promise.all([
            window.OneSignal.isPushNotificationsEnabled(),
            window.OneSignal.isOptedOut()
        ]);
        if (result[0] && !result[1]) {
            let userInfo = await JSON.parse(localStorage.getItem("userInfo"));
            const snapshot = await firebase.database().ref("User/" + userInfo.objectId).once("value")
            if (snapshot.val()) {
                if ((await window.OneSignal.getUserId()) === snapshot.val().oneSignalId) {
                    console.log("Push notifications enabled");

                } else {
                    await currentThis.enableNotifications(userInfo);
                }
            }

        } else {
            await window.OneSignal.registerForPushNotifications();
            let userInfo = await JSON.parse(localStorage.getItem("userInfo"));
            await currentThis.enableNotifications(userInfo);
        }


    }

    enableNotifications = async (userInfo) => {
        let oneSigId = await window.OneSignal.getUserId();
        if (oneSigId !== null) {
            let newUserInfo = {
                ...userInfo,
                oneSignalId: oneSigId
            }
            await firebase.database().ref("User/" + userInfo.objectId).set(newUserInfo);
            await localStorage.setItem("userInfo", JSON.stringify(newUserInfo));
        } else
            console.log("Id is null");

    }

    /**
     * Registers the user with given details
     */
    registerUser = (firstname, lastname, email, title, password, profilePicture) => {
        return new Promise((resolve, reject) => {
            firebase.auth().createUserWithEmailAndPassword(email, password).then((user) => {
                if (profilePicture !== null) {
                    let task = firebase.storage().ref(user.user.uid).child('profile_picture').child(profilePicture.name).put(profilePicture);
                    task.on('state_changed',
                        (snapShot) => { }, (error) => {
                            reject(this._handleError(error));
                        }, () => {
                            firebase.storage().ref(user.user.uid).child('profile_picture').child(task.metadata_.name).getDownloadURL().then(firebaseURL => {
                                firebase.database().ref('profilePics/' + firebase.auth().currentUser.uid).set(firebaseURL);
                                firebase.auth().currentUser.updateProfile({
                                    photoURL: firebaseURL
                                });
                            })
                        })
                }
            }).then(async () => {
                let user = firebase.auth().currentUser;
                user.updateProfile({
                    displayName: firstname + ' ' + lastname,
                    title: title
                });
                const oneSigId = await window.OneSignal.getUserId();
                firebase.database().ref('User/' + user.uid).set({
                    firstname: firstname,
                    lastname: lastname,
                    email: email,
                    title: title,
                    objectId: user.uid,
                    oneSignalId: oneSigId,
                    createdAt: (Date.now() / 1000)
                })
                const userEmailRef = firebase.database().ref('UserEmail');
                const userEmail = {
                    email: user.email,
                    userID: user.uid
                }
                userEmailRef.push(userEmail);
                firebase.auth().currentUser.sendEmailVerification().then(() => { }).catch((error) => { reject(this._handleError(error)); })
            }).then((user) => {
                resolve(firebase.auth().currentUser);
            }, (error) => {
                reject(this._handleError(error));
            });
        });
    }

    registerUserByAdmin = async (values) => {
        return new Promise((resolve, reject) => {
            firebase.auth().createUserWithEmailAndPassword(values.email, values.password).then(async (user) => {
                const oneSigId = await window.OneSignal.getUserId();
                firebase.database().ref('User/' + user.user.uid).set({
                    firstname: '',
                    lastname: '',
                    email: values.email,
                    title: '',
                    createdBy: values.createdBy,
                    isSuperAdmin: values.isSuperAdmin,
                    objectId: user.user.uid,
                    oneSignalId: oneSigId,
                    createdAt: (Date.now() / 1000)
                });
                const userEmailRef = firebase.database().ref('UserEmail');
                const userEmail = {
                    email: values.email,
                    userID: user.user.uid
                }
                userEmailRef.push(userEmail);
            }).then((user) => {
                var auth = firebase.auth();
                auth.sendPasswordResetEmail(values.email).then(function () {

                }).catch(function (error) {
                    reject(this._handleError(error))
                });
                resolve(firebase.auth().currentUser);
            }, (error) => {
                reject(this._handleError(error));
            });
        });
    }

    /**
     * Login user with given details
     */
    loginUser = (email, password, rememberMe) => {
        let currentThis = this;
        return new Promise((resolve, reject) => {
            firebase.auth().signInWithEmailAndPassword(email, password).then(async (user) => {
                if (firebase.auth().currentUser.emailVerified === false) {
                    firebase.auth().currentUser.sendEmailVerification().then(() => { }).catch((error) => { reject(this._handleError(error)); })
                    var error = { message: "Please verify your email address and try again. Link has been resent to your email address." };
                    reject(this._handleError(error));
                }
                else {
                    localStorage.setItem("authUser", JSON.stringify(user.user))
                    await firebase.database().ref('User/' + user.user.uid).once('value', async function (snapshot) {
                        if (snapshot.val() !== null) {
                            let userInfo = JSON.stringify(snapshot.val());
                            localStorage.setItem('userInfo', userInfo);
                            await currentThis.checkEnabledNotifications();
                            const result = await Promise.all([
                                window.OneSignal.isPushNotificationsEnabled(),
                                window.OneSignal.isOptedOut()
                            ]);
                            if (result[0] && !result[1]) {
                                await window.OneSignal.setSubscription(true);
                            } else {
                                console.log("Notif not enabled/opted out");
                            }
                        }
                    });
                }
                resolve(firebase.auth().currentUser);
            }, (error) => {
                reject(this._handleError(error));
            });
        });
    }

    /**
     * forget Password user with given details
     */
    forgetPassword = (email) => {
        return new Promise((resolve, reject) => {
            firebase.auth().sendPasswordResetEmail(email, { url: window.location.protocol + "//" + window.location.host + "/login" }).then(() => {
                resolve(true);
            }).catch((error) => {
                reject(this._handleError(error));
            })
        });
    }

    /**
     * Logout the user
     */
    logout = async () => {
        let userInfo = await JSON.parse(localStorage.getItem("userInfo"));
         if (userInfo['oneSignalId'] === (await window.OneSignal.getUserId())) {
            let update = {
                oneSignalId: ""
            }
            await firebase.database().ref("User/" + userInfo.objectId).update(update);
        }
        // window.OneSignal.setSubscription(false);
        return new Promise((resolve, reject) => {
            firebase.auth().signOut().then(() => {
                localStorage.clear();
                resolve(true);
            }).catch((error) => {
                reject(this._handleError(error));
            })
        });

    }

    setLoggedInUser = (user) => {
        localStorage.setItem("authUser", JSON.stringify(user));
    }

    /**
     * Returns the authenticated user
     */
    getAuthenticatedUser = () => {
        if (!localStorage.getItem('authUser'))
            return null;
        return JSON.parse(localStorage.getItem('authUser'));
    }

    getUserDetails = (email) => {
        let user = JSON.parse(localStorage.getItem('authUser'))
        return new Promise((resolve, reject) => {
            if (user) {
                firebase.database().ref('User/').orderByChild('email').equalTo(email).on('value', (snapshot) => {
                    if (snapshot) {
                        let result = snapshot.val();
                        for (const key in result) {
                            Object.assign(user, { info: result[key] });
                        }
                        if (user.info.hasOwnProperty('ownerId')) {
                            if (user.info.objectId === user.info.ownerId) {
                                Object.assign(user.info, { isAdmin: true });
                            } else {
                                Object.assign(user.info, { isAdmin: false });
                            }
                        } else {
                            if (user.info.activePlan !== "") {
                                Object.assign(user.info, { isAdmin: false });
                            } else {
                                Object.assign(user.info, { isAdmin: true });
                            }
                        }
                        localStorage.setItem("userDetails", JSON.stringify(user));
                        resolve(user);

                    } else {
                        reject('error');
                    }
                })
            } else {
                reject('error');
            }
        })
    }


    /**
     * Handle the error
     * @param {*} error 
     */
    _handleError(error) {
        // var errorCode = error.code;
        var errorMessage = error.message;
        return errorMessage;
    }
}

let _fireBaseBackend = null;

/**
 * Initilize the backend
 * @param {*} config 
 */
const initFirebaseBackend = (config) => {
    if (!_fireBaseBackend) {
        _fireBaseBackend = new FirebaseAuthBackend(config);
    }
    return _fireBaseBackend;
}

/**
 * Returns the firebase backend
 */
const getFirebaseBackend = () => {
    return _fireBaseBackend;
}
const getFirebaseStorage = () => {
    return _fireBaseBackend.storage()
}

export { initFirebaseBackend, getFirebaseBackend, getFirebaseStorage };