import { SET_ARCHIVE_MESSAGES, CHANGE_ACTIVE_ARCHIVE_CONVERSATION_ID, RECENT_ARCHIVE_CONVERSATION_LIST, RESET_ARCHIVE_STATE } from './constants'

export const setArchiveMessages = (messages) => ({
    type: SET_ARCHIVE_MESSAGES,
    payload: messages
});

export const changeActiveArchiveConversationId = (conversationId) => ({
    type: CHANGE_ACTIVE_ARCHIVE_CONVERSATION_ID,
    payload: conversationId
});

export const setRecentArchiveList = (recentArchive) => ({
    type: RECENT_ARCHIVE_CONVERSATION_LIST,
    payload: recentArchive
});

export const resetArchiveState = () => ({
    type: RESET_ARCHIVE_STATE
});