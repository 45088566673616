import { SET_ADMIN_SEARCH_MESSAGES, CHANGE_ACTIVE_ADMIN_SEARCH_CONVERSATION_ID, RECENT_ADMIN_SEARCH_CONVERSATION_LIST, SET_FILTER_DATA, RECENT_ADMIN_SEARCH_ALL_CONVERSATION_LIST, RESET_SEARCH_STATE } from './constants'

const INIT_STATE = {
    active_admin_search_conversation_id: 1,
    admin_search_messages: [],
    recentAdminSearchConversationList: [],
    recentAdminSearchAllConversationList: [],
    filterData: {
        email: '',
        fromDate: '',
        toDate: '',
        userName: ''
    }
}

const AdminSearch = (state = INIT_STATE, action) => {
    switch (action.type) {
        case CHANGE_ACTIVE_ADMIN_SEARCH_CONVERSATION_ID:
            return { ...state, active_admin_search_conversation_id: action.payload }

        case SET_ADMIN_SEARCH_MESSAGES:
            return { ...state, admin_search_messages: action.payload };

        case RECENT_ADMIN_SEARCH_CONVERSATION_LIST:
            return { ...state, recentAdminSearchConversationList: [...action.payload] };

        case SET_FILTER_DATA:
            return { ...state, filterData: { ...action.payload } };

        case RECENT_ADMIN_SEARCH_ALL_CONVERSATION_LIST:
            return { ...state, recentAdminSearchAllConversationList: [...action.payload] };

        case RESET_SEARCH_STATE:
            return INIT_STATE;
            
        default:
            return state
    }
}

export default AdminSearch;
