import React from "react";
import { Redirect } from "react-router-dom";

// lazy load all the views
const Dashboard = React.lazy(() => import("../pages/dashboard/index"));
const StarterPage = React.lazy(() => import("../pages/StarterPage/index"));
const Users = React.lazy(() => import('../pages/admin/Users'));
// auth
const Login = React.lazy(() => import("../pages/Auth/Login"));
const Logout = React.lazy(() => import("../pages/Auth/Logout"));
const ForgetPassword = React.lazy(() => import("../pages/Auth/ForgetPassword"));
const Register = React.lazy(() => import("../pages/Auth/Register"));

// declare all routes
const authProtectedRoutes = [
    { path: "/dashboard", component: Dashboard },
    { path: "/pages-starter", component: StarterPage },
    { path: "/logout", component: Logout },
    { path: "/users", component: Users },
    {
        path: "/",
        exact: true,
        component: () => <Redirect to="/dashboard" />,
    }
];

const publicRoutes = [
    { path: "/login", component: Login },
    { path: "/forget-password", component: ForgetPassword },
    { path: "/register", component: Register }
];

const routes = [...authProtectedRoutes, ...publicRoutes];

export { authProtectedRoutes, publicRoutes, routes };
