import {
    CHAT_USER, SET_LOADING, CHANGE_ACTIVE_CONVERSATION_ID, ACTIVE_USER, FULL_USER, GET_CONTACTS, FETCH_MESSAGES, ALL_MESSAGES, RECENT_CONVERSATION_LIST, RECENT_NEW_CONVERSATION, SET_IS_OPEN_LOCATION_RULES, SET_IS_OPEN_RULES, SET_ACTIVE_CONVERSATION, RESET_CHAT_STATE
} from './constants';


const INIT_STATE = {
    active_conversation_id: 1,

    active_conversation: null,
    // profilePicture : "",

    messages: [],
    recentConversationList: [],

    recentNewConversation: [],

    active_user: 0,
    userInfo: [
        {
            id: 0,
            email: "",
            profilePicture: "",
            firstName: "",
            lastName: "",
            title: ""
        }
    ],
    contacts: null,

    isOpenRules: false,

    isOpenLocationRulesMsg: false,

    loading: {
        isLoading: false,
        selectedConversation: 1
    },
};

const Chat = (state = INIT_STATE, action) => {
    switch (action.type) {
        case CHAT_USER:
            return { ...state };

        case CHANGE_ACTIVE_CONVERSATION_ID:
            return { ...state, active_conversation_id: action.payload };

        case SET_ACTIVE_CONVERSATION:
            return { ...state, active_conversation: action.payload };

        case ACTIVE_USER:
            return { ...state, active_user: action.payload };

        case FULL_USER:
            return { ...state, userInfo: action.payload };

        case GET_CONTACTS:
            return { ...state, contacts: action.payload };

        case FETCH_MESSAGES:
            return { ...state, messages: action.payload };

        case ALL_MESSAGES:
            return { ...state, messages: [...action.payload] };

        case RECENT_CONVERSATION_LIST:
            return { ...state, recentConversationList: [...action.payload] };

        case RECENT_NEW_CONVERSATION:
            return { ...state, recentNewConversation: [...action.payload] };

        case SET_IS_OPEN_RULES:
            return { ...state, isOpenRules: action.payload };

        case SET_IS_OPEN_LOCATION_RULES:
            return { ...state, isOpenLocationRulesMsg: action.payload };

        case SET_LOADING: {
            return { ...state, loading: action.payload }
        }

        case RESET_CHAT_STATE:
            return INIT_STATE;

        default: return { ...state };
    }
}

export default Chat;