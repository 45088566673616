import { SET_ADMIN_SEARCH_MESSAGES, CHANGE_ACTIVE_ADMIN_SEARCH_CONVERSATION_ID, RECENT_ADMIN_SEARCH_ALL_CONVERSATION_LIST, RECENT_ADMIN_SEARCH_CONVERSATION_LIST, SET_FILTER_DATA, RESET_SEARCH_STATE } from './constants'

export const setAdminSearchMessages = (messages) => ({
    type: SET_ADMIN_SEARCH_MESSAGES,
    payload: messages
});

export const changeActiveAdminSearchConversationId = (conversationId) => ({
    type: CHANGE_ACTIVE_ADMIN_SEARCH_CONVERSATION_ID,
    payload: conversationId
});

export const setAdminSearchConversationList = (recentConversation) => ({
    type: RECENT_ADMIN_SEARCH_CONVERSATION_LIST,
    payload: recentConversation
});

export const setFilterData = (filter) => ({
    type: SET_FILTER_DATA,
    payload: filter
});

export const setAdminSearchAllConversationList = (conversation) => ({
    type: RECENT_ADMIN_SEARCH_ALL_CONVERSATION_LIST,
    payload: conversation
});

export const resetSearchState = () => ({
    type: RESET_SEARCH_STATE
});