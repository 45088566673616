import {
    SET_ACTIVE_FOLDER,
    ON_CAMERA_CLICKED,
    SET_FILES,
    SET_FOLDERS,
    SET_SPACE_USED,
    SET_ALERT_MESSAGE,
    SET_ALERT_COLOR,
    SET_SHARED_FOLDERS,
    SET_IS_OPEN_LOC_RULES,
    RESET_NOTEBOOK_STATE,
    SET_IS_OPEN_LOADER_MODAL
} from './constants';

export const setActiveFolder = (active_folder) => ({
    type: SET_ACTIVE_FOLDER,
    payload: active_folder
});

export const setFolders = (folders) => ({
    type: SET_FOLDERS,
    payload: folders
});

export const setSharedFolders = (sharedFolders) => ({
    type: SET_SHARED_FOLDERS,
    payload: sharedFolders
});

export const setFiles = (files) => ({
    type: SET_FILES,
    payload: files
});

export const setOnCameraClicked = (type) => ({
    type: ON_CAMERA_CLICKED,
    payload: type
});

export const setSpaceUsed = (totalSize) => ({
    type: SET_SPACE_USED,
    payload: totalSize
});

export const setAlertMessage = (message) => ({
    type: SET_ALERT_MESSAGE,
    payload: message
});

export const setAlertColor = (color) => ({
    type: SET_ALERT_COLOR,
    payload: color
});

export const setIsOpenLocationRules = (isOpen) => ({
    type: SET_IS_OPEN_LOC_RULES,
    payload: isOpen
});

export const resetNotebookState = () => ({
    type: RESET_NOTEBOOK_STATE
});

export const setIsOpenLoaderModal = (isOpen) => ({
    type: SET_IS_OPEN_LOADER_MODAL,
    payload: isOpen
});