import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { getFirebaseBackend } from "../../helpers/firebase";


import {
    LOGIN_USER,
    LOGOUT_USER,
    REGISTER_USER,
    FORGET_PASSWORD
} from './constants';


import {
    loginUserSuccess,
    registerUserSuccess,
    forgetPasswordSuccess,
    apiError,
    addUserDetails
} from './actions';


//Initilize firebase
const fireBaseBackend = getFirebaseBackend();


/**
 * Login the user
 * @param {*} payload - username and password 
 */
function* login({ payload: { email, password, rememberMe, history } }) {
     try {
        const response = yield call(fireBaseBackend.loginUser, email, password, rememberMe);
        yield put(loginUserSuccess(response));
        history.push('/dashboard');
        const userData = yield call(fireBaseBackend.getUserDetails, response.email);
        yield put(addUserDetails(userData));
    } catch (error) {
        yield put(apiError(error));
    }
}


/**
 * Logout the user
 * @param {*} param0 
 */
function* logout({ payload: { history } }) {
    try {
        yield call(fireBaseBackend.logout);
        // localStorage.clear();
        yield call(() => {
            // history.push("/login");
            localStorage.clear();
            window.location.reload();
        });
    } catch (error) { }
}

/**
 * Register the user
 */
function* register({ payload: { user, history } }) {
    try {
        const firstname = user.firstname;
        const lastname = user.lastname;
        const email = user.email;
        const title = user.title;
        const password = user.password;
        const profilePicture = user.profilePicture;
        const response = yield call(fireBaseBackend.registerUser, firstname, lastname, email, title, password, profilePicture);
        yield put(registerUserSuccess(response));
    } catch (error) {
        yield put(apiError(error));
    }
}

/**
 * forget password
 */
function* forgetPassword({ payload: { email } }) {
    try {
        const response = yield call(fireBaseBackend.forgetPassword, email);
        if (response) {
            yield put(
                forgetPasswordSuccess(
                    "Reset link are sended to your mailbox, check there first"
                )
            );
        }
    } catch (error) {
        yield put(apiError(error));
    }
}


export function* watchLoginUser() {
    yield takeEvery(LOGIN_USER, login);
}

export function* watchLogoutUser() {
    yield takeEvery(LOGOUT_USER, logout);
}

export function* watchRegisterUser() {
    yield takeEvery(REGISTER_USER, register);
}

export function* watchForgetPassword() {
    yield takeEvery(FORGET_PASSWORD, forgetPassword);
}

function* authSaga() {
    yield all([
        fork(watchLoginUser),
        fork(watchLogoutUser),
        fork(watchRegisterUser),
        fork(watchForgetPassword),
    ]);
}

export default authSaga;