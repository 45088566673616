// @flow
import {
	SET_ACTIVE_TAB,
	OPEN_USER_PROFILE_SIDEBAR,
	CLOSE_USER_PROFILE_SIDEBAR,
    OPEN_VIEW_CONTACT,
    CLOSE_VIEW_CONTACT,
    SET_CONTACT_DETAILS,
    SET_PROFILEPIC_URLS,
    SET_MYPROFILEPIC_URL,
    SET_SETTINGS_TAB,
    RESET_LAYOUT_STATE
} from "./constants";

const INIT_STATE = {
	activeTab : "chat",
	userSidebar : false,
    viewContact : false,
    contactDetails : null,
    profilePicUrls: [],
    myProfilePicUrl: null,
    settingsTab : null
};

const Layout = (state = INIT_STATE, action) => {
	switch (action.type) {
		case SET_ACTIVE_TAB:
			return {
				...state,
				activeTab: action.payload
			};

		case OPEN_USER_PROFILE_SIDEBAR:
			return {
				...state,
				userSidebar: true
			};

		case CLOSE_USER_PROFILE_SIDEBAR:
			return {
				...state,
				userSidebar: false
            };
        
        case OPEN_VIEW_CONTACT:
            return {
                ...state,
                viewContact: true
            };

        case CLOSE_VIEW_CONTACT:
            return {
                ...state,
                viewContact: false
            };

        case SET_CONTACT_DETAILS:
            return {
                ...state,
                contactDetails: action.payload
            };
        
        case SET_PROFILEPIC_URLS:
            return {
                ...state,
                profilePicUrls: action.payload
            };

        case SET_MYPROFILEPIC_URL:
            return {
                ...state,
                myProfilePicUrl: action.payload
            };
            
        case SET_SETTINGS_TAB:
            return {
                ...state,
                settingsTab: action.payload
            };

        case RESET_LAYOUT_STATE:
            return INIT_STATE;

		default:
			return state;
	}
};

export default Layout;
