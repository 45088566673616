import { SET_ARCHIVE_MESSAGES, CHANGE_ACTIVE_ARCHIVE_CONVERSATION_ID, RECENT_ARCHIVE_CONVERSATION_LIST, RESET_ARCHIVE_STATE } from './constants'

const INIT_STATE = {
    active_archive_conversation_id: 1,
    archive_messages: [],
    recentArchiveConversationList: []
}

const ArchiveChat = (state = INIT_STATE, action) => {
    switch (action.type) {
        case CHANGE_ACTIVE_ARCHIVE_CONVERSATION_ID:
            return { ...state, active_archive_conversation_id: action.payload}

        case SET_ARCHIVE_MESSAGES:
            return { ...state, archive_messages: action.payload };

        case RECENT_ARCHIVE_CONVERSATION_LIST:
            return { ...state, recentArchiveConversationList: [...action.payload] };

        case RESET_ARCHIVE_STATE:
            return INIT_STATE;

        default:
            return state
    }
}

export default ArchiveChat;
