export const SET_ACTIVE_FOLDER = "SET_ACTIVE_FOLDER";
export const SET_FOLDERS = "SET_FOLDERS";
export const SET_SHARED_FOLDERS = "SET_SHARED_FOLDERS";
export const SET_FILES = "SET_FILES";
export const ON_CAMERA_CLICKED = "ON_CAMERA_CLICKED";
export const SET_SPACE_USED = "SET_SPACE_USED";
export const SET_ALERT_MESSAGE = "SET_ALERT_MESSAGE";
export const SET_ALERT_COLOR = "SET_ALERT_COLOR";
export const SET_IS_OPEN_LOC_RULES = "SET_IS_OPEN_LOC_RULES";
export const RESET_NOTEBOOK_STATE = 'RESET_NOTEBOOK_STATE';
export const SET_IS_OPEN_LOADER_MODAL = 'SET_IS_OPEN_LOADER_MODAL';